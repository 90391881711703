import { Component, Input, OnInit } from "@angular/core";
import { MenuController } from "@ionic/angular";
import { Observable } from "rxjs";
import { CrmService } from "src/app/api/crm.services";
import { AuthService } from "src/app/auth/auth.service";
import { Componente } from "src/app/interfaces/interfaces";
import { AlertService } from "src/app/components/alert.service";
import { DataService } from "src/app/services/data.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  componentes: Observable<Componente[]>;

  @Input() titulo: string = "";

  public user: string = window.sessionStorage.getItem("user");
  public company;
  public status = "Conectado";
  public cases:Array<any>;
  public title;


  constructor(
    private menuCtrl: MenuController,
    private dataService: DataService,
    private crm: CrmService
  ) {

    this.crm.getCases().then(data=>{

      if(data){
        if(data.data.length > 0){
          this.cases = data.data;
          this.company = this.cases[0].attributes.account_name;
          console.log("EMPRESA 1", this.company )
        }else{
          alert ("No se identificó el nombre de la empresa");
        }
      }
    });
    }

  ngOnInit() {
    this.componentes = this.dataService.getMenuOpts();
    
  }
  mostrarMenu() {
    this.menuCtrl.open("first");
  }

}
