import { LOCALE_ID, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouteReuseStrategy } from "@angular/router";
import { HttpClientModule } from "@angular/common/http";

import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";

import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";

import { JWT_OPTIONS, JwtHelperService } from "@auth0/angular-jwt";


import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CommonModule, DatePipe } from "@angular/common";
// import { IonicSelectableModule } from "ionic-selectable";

import { LoginPageModule } from "./auth/login/login.module";
import { ComponentsModule } from "./components/components.module";
import { RouterService } from "./services/router.service";
import { InformationPageModule } from "./information/information.module";
import { LoginPage } from "./auth/login/login.page";
import { AuthModule } from "./auth/auth.module";
import { AuthService } from "./auth/auth.service";
import { CrmService } from "./api/crm.services";
import { Endpoint } from "./api/endPoint";
import { ModalsCustomerPendingComponent } from "./modals/modals-customer-pending/modals-customer-pending.component";
import { AlertComponent } from "./components/alert/alert.component";


@NgModule({
  declarations: [AppComponent,
    ],

  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    ComponentsModule,
    LoginPageModule,
    FormsModule,
    // IonicSelectableModule,
    ReactiveFormsModule,
    CommonModule,
    InformationPageModule,
    AuthModule,
   
    
  ],

  providers: [
    Endpoint,
    RouterService,
    AuthService,
    LoginPage,
    DatePipe,
    CrmService,

    // { provide: LOCALE_ID, useValue: "es" },
    { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    JwtHelperService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
