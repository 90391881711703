// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
.exit-case {
  display: inline;
  width: 30px; /* Ancho deseado */
  height: auto; /* La altura se ajusta automáticamente */
}

.icon-footer {
  display: flex;
  margin-top: 2vh;
}

.padding-footer {
  margin-top: 2vh;
}`, "",{"version":3,"sources":["webpack://./src/app/app.component.scss"],"names":[],"mappings":"AAAA,gBAAgB;AAAhB;EACI,eAAA;EACA,WAAA,EAAA,kBAAA;EACA,YAAA,EAAA,wCAAA;AAEJ;;AACA;EACI,aAAA;EACA,eAAA;AAEJ;;AAAA;EACI,eAAA;AAGJ","sourcesContent":[".exit-case {\n    display: inline;\n    width: 30px; /* Ancho deseado */\n    height: auto; /* La altura se ajusta automáticamente */\n}\n\n.icon-footer {\n    display:flex;\n    margin-top: 2vh\n}\n.padding-footer {\n    margin-top: 2vh;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
