// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
.exit-case {
  width: 20px;
  /* Ancho deseado */
  height: auto;
  /* La altura se ajusta automáticamente */
  margin-left: auto;
  margin-right: 3vh;
}

.mayus {
  text-transform: lowercase;
}

.ion-labels {
  color: rgb(7, 163, 163);
  font-size: large;
  font-weight: bold;
}

img {
  margin-top: 0%;
  font-size: small;
  text-align: center;
}

.avatar-conect {
  margin-left: 75%;
  justify-content: center;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/app/components/header/header.component.scss"],"names":[],"mappings":"AAAA,gBAAgB;AAAhB;EACI,WAAA;EACA,kBAAA;EACA,YAAA;EACA,wCAAA;EACA,iBAAA;EACA,iBAAA;AAEJ;;AACA;EACI,yBAAA;AAEJ;;AACA;EACI,uBAAA;EACA,gBAAA;EACA,iBAAA;AAEJ;;AASA;EACI,cAAA;EACA,gBAAA;EACA,kBAAA;AANJ;;AAUA;EACI,gBAAA;EACA,uBAAA;EACA,mBAAA;AAPJ","sourcesContent":[".exit-case {\n    width: 20px;\n    /* Ancho deseado */\n    height: auto;\n    /* La altura se ajusta automáticamente */\n    margin-left: auto;\n    margin-right: 3vh;\n}\n\n.mayus {\n    text-transform: lowercase;\n}\n\n.ion-labels {\n    color: rgb(7, 163, 163);\n    font-size: large;\n    font-weight: bold;\n}\n\n// .p-company {\n//     margin-left: 3vh;\n//     margin-top: 0%;\n//     color: rgb(164, 16, 73);\n//     font-weight: bold;\n\n// }\n\nimg {\n    margin-top: 0%;\n    font-size: small;\n    text-align: center;\n\n}\n\n.avatar-conect {\n    margin-left: 75%;\n    justify-content: center;\n    align-items: center;\n}\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
