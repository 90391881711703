import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-modal-manual',
  templateUrl: './modal-manual.component.html',
  styleUrls: ['./modal-manual.component.scss'],
})
export class ModalManualComponent implements OnInit {

  constructor( private modalsCtrl: ModalController) { }

  ngOnInit() {}

  closedModals() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalsCtrl.dismiss({
      dismissed: true,
    });
  }

}
