import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import { AuthGuardService } from "./auth.guard";


const routes: Routes = [

  {
    path: "homePage",
    loadChildren: () =>
      import("./pages/homePage/homePage.module").then(
        (m) => m.HomePagePageModule
      ),
    canActivate: [AuthGuardService],
  },
  {
    path: "case",
    loadChildren: () =>
      import("./pages/case/case.module").then((m) => m.CasePageModule),
    canActivate: [AuthGuardService],
  },
  {
    path: "record",
    loadChildren: () =>
      import("./pages/record/record.module").then((m) => m.RecordPageModule),
    canActivate: [AuthGuardService],
  },
  {
    path: "login",
    loadChildren: () =>
      import("../app/auth/login/login.module").then((m) => m.LoginPageModule),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      // relativeLinkResolution: "legacy",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
