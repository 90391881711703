import { Component, OnInit } from "@angular/core";
import { Validators, UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { AuthService } from "../auth.service";
import { AlertService } from "../../components/alert.service";

import { message } from "../../app.config";
import { AppComponent } from "src/app/app.component";

@Component({
  selector: "app-login",
  templateUrl: "./login.page.html",
  styleUrls: ["./login.page.scss"],
})
export class LoginPage implements OnInit {
  formLogin: UntypedFormGroup;
  showPassword = false;
  passwordToggleIcon = "eye";
  isLogged = false;
  isLoginFail = false;

  constructor(
    private authService: AuthService,
    private alertService: AlertService,
    private formBuilder: UntypedFormBuilder,
    private panel: AppComponent
  ) {
    this.panel;
  }

  ngOnInit() {
    this.createForm();
    this.isLoginFail = false;
  }

  async ionViewWillEnter() {}

  togglePassword(): void {
    this.showPassword = !this.showPassword;

    if (this.passwordToggleIcon == "eye") {
      this.passwordToggleIcon = "eye-off";
    } else {
      this.passwordToggleIcon = "eye";
    }
  }

  createForm() {
    this.formLogin = this.formBuilder.group({
      user: ["", Validators.required],
      password: ["", Validators.required],
    });
  }

  onEnter() {
    this.login();
  }


  async login() {
    if (this.formLogin.valid) {
      // Se busca el ID del usuario
      await this.authService.preloginUser(this.formLogin.value).then(
        (data) => {
          if (data.client_id != "null" && data.client_id != null) {
            localStorage.setItem("user",JSON.stringify(Object.assign({...data, ...{ password: this.formLogin.value.password },
            })));

            sessionStorage.setItem("user",JSON.stringify(Object.assign({...data, ...{ password: this.formLogin.value.password },
            })));
          } else {
            localStorage.removeItem("user");
            sessionStorage.removeItem("user");
          }
        },
        (error) => {
          this.alertService.viewMessageToastControllerError(
            message.problemaDeConexion
          );
        }
      );

      // Si existe el ID de usuario
      if (
        localStorage.getItem("user") != undefined &&
        localStorage.getItem("user") != "undefined" &&
        localStorage.getItem("user") != null &&
        localStorage.getItem("user") != "null" &&
        localStorage.getItem("user") != ""
      ) {
        const user = JSON.parse(localStorage.getItem("user"));
        await this.authService.loginUser().then(
          (data) => {
            if (data) {
              const user = JSON.parse(localStorage.getItem("user"));
              localStorage.setItem(
                "user",
                JSON.stringify(Object.assign({ ...user, ...data }))
              );
            }
            this.isLogged = true;
            this.isLoginFail = false;
          },
          (error) => {
            // Si la contraseña no es valida arroja un error 401 por no estar autorizado
            this.isLogged = false;
            this.isLoginFail = true;
            if (error.status == 401) {
              localStorage.removeItem("user");
              
              // this.alertService.viewMessageToastControllerError(message.usuarioNoValido);
            } else {
              this.alertService.viewMessageToastControllerError(
                message.problemaDeConexion
              );
            }
          }
        );

        // Si la contraseña es valida
        if (
          localStorage.getItem("user") != undefined &&
          localStorage.getItem("user") != "undefined" &&
          localStorage.getItem("user") != null &&
          localStorage.getItem("user") != "null" &&
          localStorage.getItem("user") != ""
        ) {
          await this.authService
            .getAccount(JSON.parse(localStorage.getItem("user")).client_id)
            .then(
              (data) => {
                const user = JSON.parse(localStorage.getItem("user"));
                localStorage.setItem(
                  "user",
                  JSON.stringify(
                    Object.assign({
                      ...user,
                      ...{ account_id: data.data[0].id },
                    })
                  )
                );

                window.location.href = "/homePage";
                this.createForm();
              },
              (error) => {
                localStorage.removeItem("user");
                this.alertService.viewMessageToastControllerError(
                  message.problemaDeConexion
                );
              }
            );
        } else {
          // Si la contraseña no es valida (mensaje)
          this.alertService.viewMessageToastControllerError(
            message.usuarioNoValido
          );
        }
      } else {
        // Si no existe el ID de usuario
        this.alertService.viewMessageToastControllerError(
          message.usuarioNoValido
        );
      }
    }
  }

  abrirChatDeSoporte() {
    const numeroTelefono = '+5746052060';
    const mensaje = 'Hola, necesito ayuda con un caso';
    const enlaceWhatsApp = `https://wa.me/${numeroTelefono}?text=${encodeURIComponent(mensaje)}`;
    window.open(enlaceWhatsApp);
  }
}
