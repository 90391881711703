export const config_crm = {

}

export const message = {
  problemaDeConexion: 'Hubo un problema de conexón',
  casoEnviadoConExito: 'Caso creado con éxito',
  casoActualizado: 'Caso actualizado',
  usuarioNoValido: 'Usuario o contraseña no valido'
}

export const type_list = [
  {'name':'Falla - ChatCenter','value':'ChatCenter'},
  {'name':'Falla - TenebitCRM','value':'TenebitCRM'},
  {'name':'Falla - 3CX','value':'3CX'},
  {'name':'Falla - Programador','value':'Campana'},
  {'name':'Falla - Kanban','value':'Kanban'},
  {'name':'Campaña','value':'Campana'},
  {'name':'Plantilla de WhatsApp','value':'Plantilla'},
  {'name':'Capacitación','value':'Capacitacion'},
  {'name':'Cargue Masivo','value':'Cargue_Masivo'},
  {'name':'Desarrollo','value':'Desarrollo'},
  {'name':'Error','value':'Error'},
  {'name':'Parametrización','value':'Parametrizacion'},
  {'name':'Reportes/Informes','value':'Reportes'},
  {'name':'Crear/Editar Usuario','value':'desconocimiento_usuario'},
  {'name':'Problema de Datos','value':'problema_datos'},
];

export const priority_list = [
  {'name':'Alta','value':'P1'},
  {'name':'Media','value':'P2'},
  {'name':'Baja','value':'P3'}
];

export const status_list = [
  {'name':'Nuevo','value':'Open_New'},
  {'name':'Asignado','value':'Open_Assigned'},
  {'name':'En progreso','value':'Open_InProgress'},
  {'name':'Pendiente','value':'Open_Pending_Input'},
  {'name':'Cerrado','value':'Closed_Closed'},
  {'name':'Rechazado','value':'Closed_Rejected'},
  {'name':'Duplicado','value':'Closed_Duplicate'},
];

export const messageForm = {
  casesForm:{
    'name': [
		    { type: 'required', message: '*' },
	   ],
     'status': [
 		    { type: 'required', message: '*' },
 	   ],
    'type': [
		    { type: 'required', message: '*' },
	   ],
	   'priority': [
		    { type: 'required', message: '*' },
	    ],
      'fecha_esperada_realizacion_c': [
		    { type: 'required', message: '*' }
	    ],
      'description': [
        { type: 'required', message: '*' }
      ]
  }
}

export const barChartData = [
// {data: [], label: 'Casos totales',
//           'borderColor' : 'rgba(240,131,119,0.8)',
//           'hoverBorderColor' : 'rgba(244,168,159,0.8)',
//           'hoverBackgroundColor': 'rgba(244,168,159,0.8)',
//           'backgroundColor':'rgba(240,131,119,0.8)'},
{data: [], label: 'Abiertos',
                    'borderColor' : 'rgba(175,43,0,0.8)',
                    'hoverBorderColor' : 'rgba(175,43,0,0.8)',
                    'hoverBackgroundColor': 'rgba(175,43,0,0.8)',
                    'backgroundColor':'rgba(254,63,0,0.8)'},

{data: [], label: 'Cerrados',
                    'borderColor' : 'rgba(255,222,5,0.8)',
                    'hoverBorderColor' : 'rgba(255,222,5,0.8)',
                    'hoverBackgroundColor': 'rgba(255,222,5,0.8)',
                    'backgroundColor':'rgba(255,235,103,0.8)'},

]

export const barChartOptions = {
  scaleShowVerticalLines: false,
  exportEnabled: true,
  responsive: true,
  title: {
        display: true,
        text: 'Casos mensuales'
    },
  maintainAspectRatio: false,
  tooltips: {
       mode: 'label',
       enabled: true,
    },
  scales: {
    xAxes: [{
      display: true,
      stacked: true,
      scaleLabel: {
        display: true,
        labelString: 'Mes-Año'
      },
    }],
    yAxes: [{
      display: true,
      stacked: true,
      position: 'left',
      scaleLabel: {
        display: true,
        labelString: '# Casos'
      },
      ticks: {
        beginAtZero: true,
      }
    }]
  },

  annotation: {
      annotations: [
        {

          drawTime: "afterDatasetsDraw",
          id: "hline",
          type: "line",
          mode: "horizontal",
          scaleID: "y-axis-0",
          value: '5',
          borderColor: "black",
          borderWidth: 2,
          label: {
            // backgroundColor: "red",
            content: "Limite de casos",
            enabled: true,
            position: "center"
          }
        }
      ]
    }


}
