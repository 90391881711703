// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-content {
  font-family: "Overpass", sans-serif;
  font-weight: normal;
  font-size: 100%;
  margin: 0;
  color: #ffffff;
}

.img {
  --background: url('SM013857.jpeg') 0 0/100% 100% no-repeat;
}

.contenedor {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  min-width: 100vw;
  min-height: 100vh;
  width: 100%;
  height: 100%;
}

.central {
  max-width: 500px;
  width: 100%;
}

.imgLogo {
  width: 150%;
}

.imgApp {
  width: 50%;
}

.login {
  width: 100%;
  padding: 5px 30px;
  background-color: #0a2639;
  border-radius: 3px 3px 3px 3px;
  -moz-border-radius: 3px 3px 3px 3px;
  -webkit-border-radius: 3px 3px 3px 3px;
  box-sizing: border-box;
}

ion-label {
  color: black;
  font-size: x-large;
}

ion-card {
  background-color: white;
}

ion-input {
  color: black;
}

a {
  color: red;
}`, "",{"version":3,"sources":["webpack://./src/app/auth/login/login.page.scss"],"names":[],"mappings":"AACA;EACE,mCAAA;EACA,mBAAA;EACA,eAAA;EAEA,SAAA;EACA,cAAA;AADF;;AAKA;EACE,0DAAA;AAFF;;AAIA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EAEA,SAAA;EACA,UAAA;EACA,gBAAA;EACA,iBAAA;EACA,WAAA;EACA,YAAA;AAFF;;AAMA;EACE,gBAAA;EACA,WAAA;AAHF;;AAKA;EACE,WAAA;AAFF;;AAIA;EACE,UAAA;AADF;;AAIA;EACE,WAAA;EACA,iBAAA;EACA,yBAAA;EACA,8BAAA;EACA,mCAAA;EACA,sCAAA;EACA,sBAAA;AADF;;AAIA;EACI,YAAA;EACA,kBAAA;AADJ;;AAIA;EACI,uBAAA;AADJ;;AAGA;EACI,YAAA;AAAJ;;AAGA;EACE,UAAA;AAAF","sourcesContent":["\nion-content {\n  font-family: \"Overpass\", sans-serif;\n  font-weight: normal;\n  font-size: 100%;\n\n  margin: 0;\n  color: #ffffff;\n\n}\n\n.img{\n  --background: url(\"../../../assets/SM013857.jpeg\") 0 0/100% 100% no-repeat;\n}\n.contenedor {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  margin: 0;\n  padding: 0;\n  min-width: 100vw;\n  min-height: 100vh;\n  width: 100%;\n  height: 100%;\n  \n}\n\n.central {\n  max-width: 500px;\n  width: 100%;\n}\n.imgLogo {\n  width: 150%;\n}\n.imgApp {\n  width: 50%;\n}\n\n.login {\n  width: 100%;\n  padding: 5px 30px;\n  background-color: #0a2639;\n  border-radius: 3px 3px 3px 3px;\n  -moz-border-radius: 3px 3px 3px 3px;\n  -webkit-border-radius: 3px 3px 3px 3px;\n  box-sizing: border-box;\n}\n\nion-label {\n    color: black;\n    font-size: x-large;\n}\n\nion-card {\n    background-color: white;\n}\nion-input {\n    color: black;\n}\n\na {\n  color: red;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
