import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-modals-welcome',
  templateUrl: './modals-welcome.component.html',
  styleUrls: ['./modals-welcome.component.scss'],
})
export class ModalsWelcomeComponent implements OnInit {

  constructor(
    private modalsCtrl: ModalController
  ) { }

  ngOnInit() {}

  closedModals() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalsCtrl.dismiss({
      dismissed: true,
    });
  }
}
