import { Component, OnInit } from "@angular/core";
import { AlertController } from "@ionic/angular";

@Component({
  selector: "app-alert",
  templateUrl: "./alert.component.html",
  styleUrls: ["./alert.component.scss"],
})
export class AlertComponent implements OnInit {
  constructor(
    private alertCtrl: AlertController
  ) {}

  ngOnInit(){}

  async alertCreateCase() {
    const alert = await this.alertCtrl.create({
      header: 'Caso creado',
      subHeader: 'Numero de caso',
      message: 'Hemos recibito tu caso pronto trabajaremos en el',
      buttons: ['Cerar ventana']
    });
  
    await alert.present();
  }

  

  
}
