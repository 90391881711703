import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { IonicModule } from "@ionic/angular";

import { HeaderComponent } from "./header/header.component";
import { PopoverInfoComponent } from "./popover-info/popover-info.component";
import { FooterComponent } from "./footer/footer.component";
import { PanelComponent } from "./panel/panel.component";
import { RouterModule } from "@angular/router";

import { ModalsCustomerPendingComponent } from "../modals/modals-customer-pending/modals-customer-pending.component";
import { ModalsNewsComponent } from "../modals/modals-news/modals-news.component";
import { ModalsWelcomeComponent } from "../modals/modals-welcome/modals-welcome.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AlertComponent } from "./alert/alert.component";

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    PopoverInfoComponent,
    PanelComponent,
    ModalsCustomerPendingComponent,
    ModalsNewsComponent,
    ModalsWelcomeComponent,
    AlertComponent
    
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    PopoverInfoComponent,
    PanelComponent,
    ModalsCustomerPendingComponent,
    ModalsNewsComponent,
    ModalsWelcomeComponent,
    FormsModule,
    ReactiveFormsModule,
    AlertComponent
    
  ],
  imports: [
    CommonModule, 
    IonicModule, 
    RouterModule, 
    FormsModule, 
    ReactiveFormsModule],
})
export class ComponentsModule {}
