import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { AlertService } from '../components/alert.service';

@Injectable()
export class RouterService {

  private previousUrl: string = undefined;
  private currentUrl: string = undefined;


  constructor(
    private router : Router,

  ) {

  }

  init(){
    this.currentUrl = this.router.url;
    this.router.events.subscribe( async event => {
      if (event instanceof NavigationEnd) {
        localStorage.setItem('previousUrl', this.currentUrl);
        localStorage.setItem('currentUrl', event.url);

      };

    });
  }
}
