import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { UntypedFormBuilder, UntypedFormGroup,  Validators } from '@angular/forms';
import { CrmService } from 'src/app/api/crm.services';
import { message } from 'src/app/app.config';
import { AlertService } from 'src/app/components/alert.service';

@Component({
  selector: 'app-modals-customer-pending',
  templateUrl: './modals-customer-pending.component.html',
  styleUrls: ['./modals-customer-pending.component.scss'],
})
export class ModalsCustomerPendingComponent implements OnInit {

  @Input() infoCaso: any;
  formCases: UntypedFormGroup;
  public dataArray;
  private base64File: any;
  private updateFile: string;
  private onBeforeUpload: any;

  constructor(
    private modalsCtrl: ModalController,
    private crm: CrmService,     
    private formBuilder:UntypedFormBuilder,    
    private alertService:AlertService,
   ) { 

  }

  ngOnInit() {
     this.createForm();
      console.log('Registro a editar:', this.infoCaso);
      this.dataArray = this.infoCaso;
  }

  createForm() {
    this.formCases = this.formBuilder.group({
      respuesta: ['', Validators.required],
      file: [''],
    });

  }


  arrayBufferToBase64(buffer: ArrayBuffer): string {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;

     for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return btoa(binary);
  }

  cargarArchivo(event: any) {
    const file: File = event.target.files[0];
    this.updateFile = file.name;
    this.onBeforeUpload = file.type;
    
      console.log("Nombre: ", this.updateFile );
      console.log("Tipo: ", this.onBeforeUpload ) 

      if (file) {
        const reader = new FileReader();
            reader.onloadend = () => {
          const base64String = this.arrayBufferToBase64(reader.result as ArrayBuffer);
          console.log('Archivo en base64:', base64String);
          this.base64File = base64String;
        };
        reader.readAsArrayBuffer(file);
      }
  }

async editarCaso() {
  if (this.formCases.valid) {
    const params = {
      "data": {
        'type': 'Cases', 
        'id': this.infoCaso.id, 
        'attributes': {'respuesta_c': this.formCases.value.respuesta}
      }
    };
    console.log('Respuesta: ', params);

    await this.crm.actualizarRegistro(params).then((data) => {

     if (data) {
        const params = {
          "data": {
            "type": "Notes",
            "attributes": {
              "name": this.updateFile,
                 "filename":  this.updateFile,
                 "file_mime_type": this.onBeforeUpload,
                 "parent_type": "Cases",
                 "parent_id": this.infoCaso.id,
              "filecontents": this.base64File,
              "description": this.formCases.value.respuesta
            }

          }
        }
        this.crm.postCases(params).then((data) => {
          console.log('Archivo: ', params);
          if(data) {
            this.alertService.viewMessageToastControllerSuccess(message.casoActualizado);
            this.closedModals();
          }
        });
            
          }
    });
  }
}

  closedModals() {
    this.modalsCtrl.dismiss({
      dismissed: true,
    });
  }
}



