// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-title {
  --color: #ffffff;
  font-size: x-large;
  font-weight: bold;
  text-align: center;
}

ion-icon {
  color: #ffffff;
  font-size: 30px;
  transform: rotate(90deg);
  margin-right: 3%;
}`, "",{"version":3,"sources":["webpack://./src/app/modals/modal-manual/modal-manual.component.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,kBAAA;EACA,iBAAA;EACA,kBAAA;AACJ;;AAEA;EAEI,cAAA;EACA,eAAA;EACA,wBAAA;EACA,gBAAA;AAAJ","sourcesContent":["ion-title {\n    --color: #ffffff;\n    font-size: x-large;\n    font-weight: bold;\n    text-align: center;\n}\n\nion-icon {\n\n    color: #ffffff;\n    font-size: 30px;\n    transform: rotate(90deg);\n    margin-right: 3%;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
