import { Component, OnInit } from "@angular/core";

import { AlertController, ModalController, Platform } from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";

import { Observable } from "rxjs";

import { NavigationEnd, Router } from "@angular/router";
import { DataService } from "src/app/services/data.service";
import { Componente } from "src/app/interfaces/interfaces";

import { ModalManualComponent } from "src/app/modals/modal-manual/modal-manual.component";
import { LoginPage } from "src/app/auth/login/login.page";
import { AuthService } from "src/app/auth/auth.service";
@Component({
  selector: "app-panel",
  templateUrl: "./panel.component.html",
  styleUrls: ["./panel.component.scss"],
})
export class PanelComponent implements OnInit {
  componentes: Observable<Componente[]>;
  currentRoute: string;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private dataService: DataService,
    private router: Router,
    private login: AuthService,
    private alertCtrl: AlertController,
    private modalsCtrl: ModalController
  ) {
    this.initializeApp();
    this.urlAssigner();
  }

  ngOnInit() {
  }

  urlAssigner() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentRoute = event.url;
      }
      console.log(" this.currentRoute", this.currentRoute);
    });
  }

  async manualUso() {
    const modal = await this.modalsCtrl.create({
      component: ModalManualComponent,
      componentProps: {
        clinicName: "",
        clinicAddress: "",
      },
    });

    await modal.present();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
      this.componentes = this.dataService.getMenuOpts();
    });
  }



  onLogout() {
    if (this.currentRoute === "/login") {
        return;
    }
    this.login.logoutUser();
  }

  async presentAlert() {
    const alert = await this.alertCtrl.create({
      header: "Falló la autenticación",
      message: "No has iniciado sesión que bestia",
      buttons: ["Aceptar"],
    });

    await alert.present();
  }

  abrirChatDeSoporte() {
    const numeroTelefono = '+5746052060';
    const mensaje = 'Hola, necesito ayuda con un caso';
    const enlaceWhatsApp = `https://wa.me/${numeroTelefono}?text=${encodeURIComponent(mensaje)}`;
    window.open(enlaceWhatsApp);
  }
}
