import { Injectable } from '@angular/core';
import { ToastController, LoadingController, AlertController} from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor(
    private loadingCtrl:LoadingController,
    private toasCtrl:ToastController,

    ) { }

  async viewMessageToastController(message:string){
    const toast = await this.toasCtrl.create({
      message: message, duration: 5000
    })
    toast.present();
  }

  async viewMessageToastControllerSuccess(message:string){
    const toast = await this.toasCtrl.create({
      message: message, duration: 5000,
      color:"success"
    })
    toast.present();
  }

  async viewMessageToastControllerError(message:string){
    const toast = await this.toasCtrl.create({
      message: message, duration: 5000,
      color:"danger"
    })
    toast.present();
  }

  async viewPresentLoading(){
    return await this.loadingCtrl.create({
      message: 'Espere un momento...',
      duration: 4000,
      spinner: 'bubbles',
      cssClass:"custom-loading",

    })
  }

}
