// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-icon {
  font-size: x-large;
  margin-left: 3%;
}

img {
  width: 5%;
  margin-left: 3%;
}

.content {
  display: flex;
}

ion-title {
  font-size: x-large;
  font-weight: bolder;
}

.text-small {
  font-size: 12px;
}

.toolbar {
  background-color: #ea735f;
}`, "",{"version":3,"sources":["webpack://./src/app/modals/modals-news/modals-news.component.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,eAAA;AACJ;;AAEA;EACI,SAAA;EACA,eAAA;AACJ;;AAEA;EACI,aAAA;AACJ;;AACA;EACI,kBAAA;EACA,mBAAA;AAEJ;;AAAA;EACI,eAAA;AAGJ;;AADA;EACI,yBAAA;AAIJ","sourcesContent":["ion-icon {\n    font-size: x-large;\n    margin-left: 3%;\n}\n\nimg {\n    width: 5%;\n    margin-left: 3%;\n}\n\n.content {\n    display: flex;\n}\nion-title {\n    font-size: x-large;\n    font-weight: bolder;\n}\n.text-small {\n    font-size: 12px;\n}\n.toolbar {\n    background-color: #ea735f;\n    \n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
