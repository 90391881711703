// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
.exit-case {
  display: inline;
  width: 30px; /* Ancho deseado */
  height: auto; /* La altura se ajusta automáticamente */
}

.icon-footer {
  display: flex;
  margin-top: 2vh;
  cursor: pointer;
}

.padding-footer {
  margin-top: 2vh;
}

.list-md {
  background: var(--ion-item-background, var(--ion-background-color, #0E2D45));
}`, "",{"version":3,"sources":["webpack://./src/app/components/panel/panel.component.scss"],"names":[],"mappings":"AAAA,gBAAgB;AAAhB;EACI,eAAA;EACA,WAAA,EAAA,kBAAA;EACA,YAAA,EAAA,wCAAA;AAEJ;;AACA;EACI,aAAA;EACA,eAAA;EACA,eAAA;AAEJ;;AAAA;EACI,eAAA;AAGJ;;AADA;EACI,4EAAA;AAIJ","sourcesContent":[".exit-case {\n    display: inline;\n    width: 30px; /* Ancho deseado */\n    height: auto; /* La altura se ajusta automáticamente */\n}\n\n.icon-footer {\n    display:flex;\n    margin-top: 2vh;\n    cursor: pointer;\n}\n.padding-footer {\n    margin-top: 2vh;\n}\n.list-md {\n    background: var(--ion-item-background, var(--ion-background-color, #0E2D45));\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
