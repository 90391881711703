import { Injectable, Input } from "@angular/core";

import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Endpoint } from "./endPoint";
import { catchError, retry } from "rxjs/operators";
import { Router } from "@angular/router";


@Injectable({
  providedIn: "root",
})
export class CrmService {

  REST_API_SERVER = this.config.apiEndpoint;

  constructor(private httpClient: HttpClient,
              private config: Endpoint,
              private router: Router) {

              }

  async getCases(): Promise<any> {
    const user = JSON.parse(localStorage.getItem('user'));
    if (!user || !user.access_token) {
      this.router.navigate(['/login']);
      return; // Detener la ejecución del método.
    }
  
    let account_id = `&filter[operator]=and&filter[account_id][eq]=${user.account_id}`;
  
    let url = `${this.REST_API_SERVER}V8/module/Cases?page[size]=1000&fields[Cases]=name,solicitante_c,resolution,account_name,requirimiento2_c,assigned_user_name,type,priority,status,description,date_entered,fecha_esperada_realizacion_c,case_number&sort=-date_entered&filter[account_id][eq]=${user.account_id}`;
    const type_state = JSON.parse(localStorage.getItem('state'));
    console.log(type_state);
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${user.access_token}`
    });
  
    if (type_state != 'null' && type_state != null) {
      url = `${this.REST_API_SERVER}V8/module/Cases?page[size]=1000&fields[Cases]=id,name,solicitante_c,resolution,account_name,requirimiento2_c,assigned_user_name,type,priority,status,description,date_entered,fecha_esperada_realizacion_c,case_number&sort=-date_entered&filter[status][eq]=${type_state.type_state}&filter[account_id][eq]=${user.account_id}`;
    }
    console.log(url);
  
    return this.httpClient.get(url, { headers })
      .pipe(
        retry(3), // Reintentará la solicitud un máximo de 3 veces en caso de error
        catchError((error) => {
          if (error.status === 401) {
            // Si el error es un Unauthorized (código 401), redirige a la página de inicio de sesión.
            this.router.navigate(['/login']); 
          }
          return this.handleError(error); // Continúa manejando otros errores como de costumbre.
        })
      )
      .toPromise();
  }
  

  private handleError(error: any) {
    // Puedes personalizar este método para manejar los errores según tus necesidades
    console.error('Hubo un error en la solicitud:', error);
    return Promise.reject(error.error || 'Error en el servidor');
  }

  async getAllCases(filtro = ""): Promise<any> {
    const params = JSON.parse(localStorage.getItem('params'));
    const user = JSON.parse(localStorage.getItem('user'));
    let headers = new HttpHeaders({'Content-Type':'application/json','Authorization':`Bearer ${user.access_token}`});
    const promise = new Promise ((resolve,reject)=>{
      return this.httpClient.get(`${this.REST_API_SERVER}V8/module/Cases?page[size]=1000&fields[Cases]=name,solicitante_c,resolution,account_name,assigned_user_name,type,priority,status,description,date_entered,fecha_esperada_realizacion_c,case_number&sort=-date_entered&filter[operator]=and&filter[account_id][eq]=${user.account_id}&${filtro}`,{headers:headers}).toPromise().then(data  => {
            resolve(data);
          },error=> {
            reject(error);
          }).catch(err => {Promise.reject(err.error || 'Server error');});
    });
    return promise;
}

  async postCases(data:any): Promise<any> {
    const user = JSON.parse(localStorage.getItem('user'));
    let headers = new HttpHeaders({'Content-Type':'application/json','Authorization':`Bearer ${user.access_token}`});
    let body = JSON.stringify(data);
    const promise = new Promise ((resolve,reject)=>{
      return this.httpClient.post(`${this.REST_API_SERVER}V8/module`,body,{headers:headers}).toPromise().then(data => {
          resolve(data);
        },error=> {
          reject(error);
        }).catch(err => {Promise.reject(err.error || 'Server error');
      });
    });
    return promise;
  }
    
    async actualizarRegistro(data: any): Promise<any> {
      const user = JSON.parse(localStorage.getItem('user'));
      let headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${user.access_token}` });

      try {
        const response = await this.httpClient.patch(`${this.REST_API_SERVER}V8/module`, data, { headers:headers }).toPromise();
        return response;
      } catch (error) {
        throw error;
      }
    }

    async actualizarRegistroPost(data: any): Promise<any> {
      const user = JSON.parse(localStorage.getItem('user'));
      let headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${user.access_token}` });

      try {
        const response = await this.httpClient.post(`${this.REST_API_SERVER}V8/module`, data, { headers:headers }).toPromise();
        console.log("Array datos edición: ", response);
        return response;

      } catch (error) {
        throw error;
      }
    }


    // Realiza consultas al módulo Accounts del crm para traer el campo cuenta como caso
  async getCantidadCaso(): Promise<any> {
    const params = JSON.parse(localStorage.getItem('params'));
    const user = JSON.parse(localStorage.getItem('user'));
    
    // Definir encabezados con el token de acceso
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${user.access_token}`
    });
  
    // Crear una Promesa para manejar la solicitud HTTP
    const promise = new Promise((resolve, reject) => {
      // Realizar una solicitud GET a la API de Suite CRM
      return this.httpClient.get(`${this.REST_API_SERVER}V8/module/Accounts?page[size]=1000&fields[Accounts]=name,casos_habilitados_mes_c&filter[id][eq]=${user.account_id}`, { headers: headers })
        .toPromise()
        .then(data => {
          console.log("this is promise", promise);
          resolve(data);
        })  
        .catch(err => {
          // Capturar cualquier error adicional y rechazar la promesa
          Promise.reject(err.error || 'Error del servidor');
        });
    });
  
    // Devolver la promesa
    return promise;
  }
  
    



}

