import { Component, OnInit, ViewChild } from '@angular/core';
import { IonList, ModalController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { CrmService } from 'src/app/api/crm.services';
import { AuthService } from 'src/app/auth/auth.service';
import { AlertService } from 'src/app/components/alert.service';
import { DataService } from 'src/app/services/data.service';
import { ModalsCustomerPendingComponent } from '../modals-customer-pending/modals-customer-pending.component';

@Component({
  selector: 'app-modals-news',
  templateUrl: './modals-news.component.html',
  styleUrls: ['./modals-news.component.scss'],
})
export class ModalsNewsComponent implements OnInit {
  usuarios: Observable<any>;
  @ViewChild(IonList) ionList: IonList;

  public cases: Array<any>;
  public cases_backup: Array<any>;
  public title;
  public loading;
  public estadoModals;
  public priodidaEstados;

  constructor(
    private modalsCtrl: ModalController,
    private dataService: DataService,
    private alertService: AlertService,
    private authService: AuthService,
    private crm: CrmService
  ) {
    this.getCases();


  }

  ngOnInit() {

    this.usuarios = this.dataService.getUsuarios();
  }

  async ionViewWillEnter() {

    this.initializeData();
    this.loading = await this.alertService.viewPresentLoading();
    this.loading.present();
    await this.getCases();
    this.loading.dismiss();
  }

  initializeData() {
    this.cases = [];
    this.cases_backup = [];
    this.title = undefined;
    localStorage.setItem('casesData', null);
    const state = JSON.parse(localStorage.getItem('state'));
    if (state) {
      this.title = state.title;
    }
  }

  async getCases() {

    try {
      const data = await this.crm.getCases();
      if (data && data.data.length > 0) {
        this.cases = data.data;

        this.cleanDescriptions();

        let estadoModal = this.cases[0].attributes.status;

        switch (estadoModal) {
          case 'Open_New':
            this.estadoModals = 'Caso nuevo';
            break;

          case 'Open_Assigned':
            this.estadoModals = 'Caso asignado';
            break;

          case 'Open_InProgress':
            this.estadoModals = 'Caso en progreso';
            break;

          case 'Open_Pending_Input':
            this.estadoModals = 'Caso pendiente';
            break;

          case 'Closed_Rejected':
            this.estadoModals = 'Caso rechazado';
            break;

          case 'Closed_Closed':
            this.estadoModals = 'Caso cerrado';
            break;

          case 'Closed_Duplicate':
            this.estadoModals = 'Caso duplicado';
            break;

          default:
            console.log(" No hay caso");
        }

        let priodidaEstado = this.cases[0].attributes.priority;


        switch (priodidaEstado) {
          case 'P1':
            this.priodidaEstados = 'Alta';
            break;
          case 'P2':
            this.priodidaEstados = 'Media';
            break;
          case 'P3':
            this.priodidaEstados = 'Baja';
            break;
          default:
            console.log(" No hay caso");
        }

        this.cases_backup = data.data;

      } else {
        console.log('No hay casos');
      }

    } catch (error) {
      console.error(error);
      if (error.status == 401) {
        await this.handleLoginUser();
      }
    }

  }

  cleanDescriptions() {
    this.cases.forEach(caseItem => {
      if (caseItem.attributes && caseItem.attributes.description) {
        caseItem.attributes.description = caseItem.attributes.description.replace(/<\/?p>/g, '');
      }
    });
  }

  async handleLoginUser() {
    const data = await this.authService.loginUser();
    const user = JSON.parse(localStorage.getItem('user'));
    localStorage.setItem('user', JSON.stringify(Object.assign({ ...user, ...data })));
    this.ionViewWillEnter();
  }

  onEvent(ev) {
    this.cases = this.cases_backup;
    const variable = ev.target.value;
    if (variable && variable.trim() != '') {
      this.cases = this.cases.filter((item) => {
        console.log("onEvent", this.cases)
        return item.attributes.name.toString().indexOf(variable) > -1;
      });
    }
  }

  getCase(data) {
    localStorage.setItem('casesData', JSON.stringify(data.attributes));
  }

  async editRecord(infoCaso) {
    const modal = await this.modalsCtrl.create({
      component: ModalsCustomerPendingComponent,
      componentProps: {
        infoCaso: {
          id: infoCaso.id,
          name: infoCaso.attributes.name,
          case_number: infoCaso.attributes.case_number,
          assigned_user_name: infoCaso.attributes.assigned_user_name,
          requirimiento: infoCaso.attributes.requirimiento2_c

        }
      },
    });
    await modal.present();
  }

  closedModals() {
    this.modalsCtrl.dismiss({
      dismissed: true,
    });
  }



}
