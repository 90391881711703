import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http'
import { Observable,BehaviorSubject, of, throwError, from } from 'rxjs';

import { Router, RouterEvent } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  AUTH_SERVER_ADDRESS:  string  =  'https://crmtenebit.tenebit.co';
  authSubject  =  new  BehaviorSubject(false);
  private currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<any>;

  constructor(private  httpClient:  HttpClient, 
              // private  storage:  Storage, 
              private router:Router) {
    this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('user')));
    this.currentUser = this.currentUserSubject.asObservable();
    console.log("this.currentUser", this.currentUser);
  }


  async getRelationship(relation:string): Promise<any> {
      const params = JSON.parse(localStorage.getItem('params'));
      let headers = new HttpHeaders({'Content-Type':'application/json','Authorization':`Bearer ${params.access_token}`});
      const promise = new Promise ((resolve,reject)=>{
        return this.httpClient.get(`${this.AUTH_SERVER_ADDRESS}/Api/${relation}`,{headers:headers})
           .toPromise().then(
             data => {resolve(data);},
             error=> {reject(error);})
            .catch(err => {Promise.reject(err.error || 'Server error');});
      });
      return promise;
  }

  
  //
  preloginUser(user): Promise<any> {
    const promise = new Promise ((resolve,reject)=>{
      return this.httpClient.get(`${this.AUTH_SERVER_ADDRESS}/index.php?entryPoint=ClientIdEntryPoint&clientUsername=${user.user}`)
         .toPromise().then(
           data => {resolve(data); },
           error=> {reject(error);})
          .catch(err => {Promise.reject(err.error || 'Server error');});
    });
    return promise;
  }

  async loginUser(): Promise<any> {
    let body = {"grant_type":"client_credentials","client_id":JSON.parse(localStorage.getItem('user')).client_id,"client_secret":JSON.parse(localStorage.getItem('user')).password};
      const promise = new Promise ((resolve,reject)=>{
        return this.httpClient.post(`${this.AUTH_SERVER_ADDRESS}/Api/access_token`,body)
        	 .toPromise().then(
             data => {resolve(data);},
             error=> {reject(error);})
            .catch(err => {Promise.reject(err.error || 'Server error');});
      });
      return promise;
  }

  /*
  Validación de la cuenta existente en el CRM
  */
  async getAccount(id:string): Promise<any> {
      const params = JSON.parse(localStorage.getItem('user'));
      let headers = new HttpHeaders({'Content-Type':'application/json','Authorization':`Bearer ${params.access_token}`});
      const promise = new Promise ((resolve,reject)=>{
        return this.httpClient.get(`${this.AUTH_SERVER_ADDRESS}/Api/V8/module/Accounts?fields[Accounts]=id&filter[phone_fax][eq]=${id}`,{headers:headers})
           .toPromise().then(
             data => {this.currentUserSubject.next(JSON.parse(localStorage.getItem('user'))); resolve(data);},
             error=> {reject(error);})
            .catch(err => {Promise.reject(err.error || 'Server error');});
      });

      console.log("Caso 1", promise);
      return promise;
  }

  async logoutUser(){
    localStorage.removeItem('user');
    this.currentUserSubject.next(null);
    this.router.navigate(['login']);
  }

  public currentUserValue(): any {
    return this.currentUserSubject.value;
  }
}
