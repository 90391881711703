import { Component, OnInit } from "@angular/core";

import { MenuController, Platform } from "@ionic/angular";

import { Router } from "@angular/router";
// import { TokenService } from "./login/token.service";

import { Observable } from "rxjs";
import { Componente } from "./interfaces/interfaces";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";
import { DataService } from "./services/data.service";

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"],
})
export class AppComponent implements OnInit {
  componentes: Observable<Componente[]>;

  constructor(
    private router: Router,
    // private tokenService: TokenService,
    private menuController: MenuController,
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private dataService: DataService
  ) {}

  ngOnInit() {}

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
      this.componentes = this.dataService.getMenuOpts();
    });
  }

  logOut() {
    // this.tokenService.logOut();
    this.menuController.close();
    this.router.navigate([""]);
    window.location.reload();
  }
}
