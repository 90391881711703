// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-color {
  background-color: rgb(4, 104, 106);
}

ion-content {
  --ion-background-color: transparent;
  --overflow: hidden;
  border-radius: 30px;
}

ion-button {
  text-align: center;
}

.reminder-alert {
  margin-top: 3%;
  display: flex;
  text-align: justify;
  font-size: large;
  color: black;
}

.service-alert {
  margin-top: 0%;
  text-align: justify;
  font-weight: bold;
  font-size: large;
  color: white;
}

.col-one {
  margin-left: 3%;
  margin-right: 3%;
}

.title-welcome {
  text-align: center;
  font-size: 7vh;
  font-weight: bolder;
}

.col-two {
  margin-top: 0%;
  text-align: justify;
}`, "",{"version":3,"sources":["webpack://./src/app/modals/modals-welcome/modals-welcome.component.scss"],"names":[],"mappings":"AAAA;EACE,kCAAA;AACF;;AACA;EACE,mCAAA;EACA,kBAAA;EACA,mBAAA;AAEF;;AAAA;EACE,kBAAA;AAGF;;AAAA;EACE,cAAA;EACA,aAAA;EACA,mBAAA;EACA,gBAAA;EACA,YAAA;AAGF;;AAAA;EACE,cAAA;EACA,mBAAA;EACA,iBAAA;EACA,gBAAA;EACA,YAAA;AAGF;;AAAA;EACE,eAAA;EACA,gBAAA;AAGF;;AAAA;EACE,kBAAA;EACA,cAAA;EAAgB,mBAAA;AAIlB;;AADA;EACE,cAAA;EACA,mBAAA;AAIF","sourcesContent":[".card-color {\n  background-color: rgb(4, 104, 106);\n}\nion-content {\n  --ion-background-color: transparent;\n  --overflow: hidden;\n  border-radius: 30px;\n}\nion-button {\n  text-align: center;\n}\n\n.reminder-alert {\n  margin-top: 3%;\n  display:flex;\n  text-align: justify;\n  font-size: large;\n  color: black;\n\n}\n.service-alert {\n  margin-top: 0%;\n  text-align: justify;\n  font-weight: bold;\n  font-size: large;\n  color: white;\n}\n\n.col-one {\n  margin-left: 3%;\n  margin-right: 3%;\n}\n\n.title-welcome {\n  text-align: center; \n  font-size: 7vh; font-weight: bolder;\n}\n\n.col-two {\n  margin-top: 0%; \n  text-align: justify;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
